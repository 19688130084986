import { useState, useEffect } from "react";
// Styles
import styles from "./Home.module.css";
// images
import imageProgressCompany from "../../images/home/convenio.png";

// Libraries
import { useSelector, useDispatch } from "react-redux";
// Utils
import simulatorCredit from "utils/creditSimulator";
import { setInfoCredit, setInfoUser, setInfoAuth } from "redux/Actions/Actions";
import { FaHandHoldingUsd } from "react-icons/fa";
// Components
import Header from "../../components/Header/index";
import Loader from "../../components/loader/Loader";
import ModalAlert from 'components/modalAlert/ModalAlert';
import ButtonHome from "./components/buttonHome/ButtonHome";
import ViewDefault from "./components/viewDefault/ViewDefault";
import { toast_custom } from "components/toast_custom/toast_custom";
import LoanInformation from "./components/loanInformation/LoanInformation";
import ModalPayCredit from "../../components/modalPayCredit/ModalPayCredit";
import ModalRetrenchment from "components/modalRetrenchment/ModalRetrenchment";
import ModalCreditRequest from "../../components/modalCreditRequest/ModalCreditRequest";
import ModalDetailCredit from '../Credit/History/components/modalDetailCredit/modalDetailCredit';
// Image
import image from "images/LogoConvenios.png";
// Utils
import { inicio } from "utils/pathName";
import settings from "../../utils/config";
import { useHistory } from 'react-router';
import cashFormatter from 'utils/cashFormatter';
import { apiRefreshToken } from "../../utils/Hooks/useApiRefreshToken";
// api
import { getCredit } from "Api/home/getCredit";
import { postPayCredit } from "Api/credit/postPayCredit";
import { getCreditHome } from "../../Api/home/getCreditHome";
import { requestInfoPayments } from "Api/credit/requestInfoPayments";
import { creditApproved, disbursement } from "utils/pathName";

export default function Home(props) {
  const [data, setData] = useState(null);
  const [stateCredit, setStateCredit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [userActive, setUserActive] = useState(false);
  const [IsInProcess, setIsInProcess] = useState(null);
  const [amountPayUser, setAmountPayUser] = useState('');
  const [isModalAlert, setIsModalAlert] = useState(false);
  const [creditsActive, setCreditsActive] = useState(null);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [urlUserPayCredit, setUrlUserPayCredit] = useState('');
  const [typePayment, setTypePayment] = useState('fullPayment');
  const [nextDateToConsult, setNextDateToConsult] = useState('');
  const [isModalDetailCredit, setModalDetailCredit] = useState(false);
  const [showButtonNewCredit, setShowButtonNewCredit] = useState(true);
  const [isModalAlertPayment, setIsModalAlertPayment] = useState(false);
  const [isModalRetrenchment, setIsModalRetrenchment] = useState(false);
  const [isModalCreditRequest, setIsModalCreditRequest] = useState(false);

  let history = useHistory();
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);

  useEffect(() => {
    apiGetCredit();
  }, []);

  const sesionClose = () => {
    dispatch(
      setInfoUser({
        ...userReducer.info_user,
        dni: "",
        photo: "",
        email: "",
        id_user: "",
        cellphone: 0,
        last_name: "",
        genre_key: "",
        first_name: "",
        genre_label: "",
        type_document: "",
        type_document_label: "",
      })
    );
    dispatch(
      setInfoAuth({
        token_access: "",
        token_refresh: "",
        ...userReducer.info_auth,
        cellphone: 0,
      })
    );
    history.push(inicio);
  };

  function apiGetCredit(token_access) {
    let token = token_access || userReducer[settings.token_access];

    let dataToSend = {
      id_credit: 0
    };
    setLoading(true);
    getCreditHome(dataToSend, token).then((response) => {
      console.log(`response credit home`, response);
      let { data, errored } = response;
      if (errored) {
        if (data.err_code === "USER_INVALID_PLATFORM" || data.code === "user_not_found") {
          sesionClose();
        }
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiGetCredit);
        }
      } else {
        if (!data.has_promissory_note) {
          let responseSimulatorCredit = simulatorCredit(
            +data.data_promissory_note.fee,
            parseFloat(userReducer.info_credit.vat),
            parseFloat(userReducer.info_credit.endorsement),
            parseFloat(userReducer.info_credit.central_risk),
            parseFloat(userReducer.info_credit.interest_rate),
            +data.data_promissory_note.money_lend
          );
          dispatch(
            setInfoCredit({
              ...userReducer.info_credit,
              fee: +data.data_promissory_note.fee,
              amount: +data.data_promissory_note.money_lend,
              id_credit: data.data_promissory_note.id_credit,
              monthlyCost: responseSimulatorCredit.monthlyCost,
            })
          );
          history.replace({
            pathname: creditApproved,
            state: { changeCredit: false, trackinLogin: true }
          })
        } else if (!data.has_disbursement) {
          dispatch(
            setInfoCredit({
              ...userReducer.info_credit,
              fee: +data.data_disbursement.installments,
              id_credit: data.data_disbursement.id_credit,
              monthlyCost: data.data_disbursement.monthly_fee,
              amount: +data.data_disbursement.requested_amount,
            })
          );
          history.replace(disbursement)
        } else {
          setData(data.credit);
          setUserActive(data.user_active)
          setStateCredit(data.credit.state)
          setCreditsActive(data.credits_actives)
          setValidate(data.validate_new_query_dc)
          setNextDateToConsult(data.next_date_search_dc)
          setShowButtonNewCredit(data.show_button_new_credit)
          if (!data.user_active) {
            setIsInProcess(false);
          } else {
            setIsInProcess(true);
          }
        }
        setLoading(false);
      }
    });
  }

  const apiGetdetailCredit = () => {
    setLoading(true);
    let token = userReducer[settings.token_access];
    let dataToSend = {
      id_credit: data.id_credit,
    };
    getCredit(dataToSend, token).then((response) => {
      setLoading(false);
      console.log(`response detail credit`, response);
      let { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiGetdetailCredit);
        }
      } else {
        setSelectedCredit(data.credit);
        setModalDetailCredit(true);
      }
    });
  };

  const apiGetRequestInfoPayment = () => {
    setLoading(true);
    let token = userReducer[settings.token_access];
    let dataToSend = {
      id_credit: data.id_credit,
    };
    requestInfoPayments(dataToSend, token).then((response) => {
      setLoading(false);
      // console.log(`response request info payments`, response);
      let { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiGetRequestInfoPayment);
        }
      } else {
        toast_custom(
          "Solicitud enviada",
          "success",
          4000,
          "top-right"
        );
        setIsModalAlert(false)
      }
    });
  };

  const apiPostPayCredit = () => {
    if (typePayment === 'partialPayment' && amountPayUser === '') {
      toast_custom(
        "Debes ingresar el monto del abono",
        "error",
        4000,
        "top-right"
      );
      return
    }
    if (typePayment === 'partialPayment' && amountPayUser <= 10000) {
      toast_custom(
        "El abono parcial debe ser mayor a $10.000",
        "error",
        4000,
        "top-right"
      );
      return
    }
    setLoading(true);
    let token = userReducer[settings.token_access];
    let dataToSend = { id_payment: data.next_payment.id_payment };
    if (typePayment === 'partialPayment') {
      dataToSend.amount_custom_user = +amountPayUser
    }
    // console.log('dataToSend', dataToSend)
    postPayCredit(dataToSend, token).then((response) => {
      setLoading(false);
      // console.log(`response pay credit`, response);
      let { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiPostPayCredit);
        }
      } else {
        setIsModalAlertPayment(false)
        setUrlUserPayCredit(data.url)
        setAmountPayUser('')
        window.open(`https://${data.url}`, '_blank')
      }
    });
  };

  return (
    <>
      <ModalAlert
        image={image}
        isTwoButton={true}
        buttonCloseText="Volver"
        isModalAlert={isModalAlert}
        buttonSuccessText="Consultar"
        colorTitle={"var(--MEDIUM_BLUE)"}
        setIsModalAlert={setIsModalAlert}
        buttonSuccessClick={() => apiGetRequestInfoPayment()}
        text2="correo electronico y whatsapp registrado"
        text="Se te enviará toda la información de tu saldo al"
        backgroundColorSuscces={"var(--COLOR_SUCCESS)"}
        buttonCloseClick={() => setIsModalAlert(false)}
      />
      <ModalPayCredit
        setLoading={setLoading}
        isModalAlert={isModalAlertPayment}
        setIsModalAlert={setIsModalAlertPayment}
        idPayment={data && data.next_payment && data.next_payment.id_payment}
        valueFee={data && data.next_payment && +data.next_payment.total_value_with_past_due + +data.collection_management}
        text={`${cashFormatter(data && data.next_payment && data.next_payment.total_value_with_past_due + +data.collection_management)}`}
      />
      {selectedCredit && (
        <ModalDetailCredit
          selectedCredit={selectedCredit}
          isModalDetailCredit={isModalDetailCredit}
          setModalDetailCredit={setModalDetailCredit}
        />
      )}
      <Header />
      <Loader loading={loading} />
      <ModalRetrenchment
        isModalRetrenchment={isModalRetrenchment}
        setIsModalRetrenchment={setIsModalRetrenchment}
      />
      <ModalCreditRequest
        isModalCreditRequest={isModalCreditRequest}
        setIsModalCreditRequest={setIsModalCreditRequest}
      />
      {data !== null && (
        <div className={styles.home}>
          {!IsInProcess ? (
            <ViewDefault
              validate={validate}
              nextDateToConsult={nextDateToConsult}
              setIsModalCreditRequest={setIsModalCreditRequest}
            />
          ) : (
            <div className={styles.view}>
              <div className={styles.container_image}>
                <img src={imageProgressCompany} alt="Estado de crédito" />
              </div>
              <p className={styles.text}>
                {
                  stateCredit === 'IN_REVIEW' ? "Esperando por la aprobación de tu empresa" : (
                    stateCredit === 'IN_PROCESS' ? "¡Estás mas cerca de cumplir tu meta!"
                      : "¡Felicitaciones estas al dia con nosotros!")
                }
              </p>
              {creditsActive ?
                <>
                  {data.next_payment.has_past_due &&
                    <p className={styles.textMora}>
                      Tu crédito se encuentra en mora, realiza el pago y evita el reporte en centrales de riesgo.
                    </p>
                  }
                  <LoanInformation credit={data} />
                </>
                : <></>
              }
              <div className={styles.container_buttons} style={{ justifyContent: 'center' }}>
                <ButtonHome
                  // link={true}
                  // to={creditHistory}
                  // text={"Historial de pago"}
                  text={"Solicitar saldo"}
                  background={"MEDIUM_BLUE"}
                  opacity={creditsActive ? 1 : .3}
                  icon={FaHandHoldingUsd}
                  onClick={() => {
                    if (data.id_credit && creditsActive) {
                      setIsModalAlert(true)
                    }
                  }}
                />
              </div>
              {showButtonNewCredit &&
                <div className={styles.container_link}>
                  <div
                    className={styles.link}
                    onClick={() => setIsModalCreditRequest(true)}
                  >
                    <p className={styles.title}>
                      {!userActive
                        ? "Solicitar nuevo crédito"
                        : "Solicitar crédito"
                      }
                    </p>
                    <p className={styles.text_link}>
                      A partir de este momento puedes retanquear tu crédito
                    </p>
                    <p className={styles.click}>Haz click aquí</p>
                  </div>
                </div>
              }
            </div>
          )}
        </div>
      )
      }
    </>
  );
}
